@use '../abstracts/mixins' as *;

#header {
    @include background-image;
    background-image: url("../../../public/images/construction-wallpaper.webp");
    background-position: 20% 30%;
    margin-bottom: 5px;
    
    .background-filter {
        height: 100%;
        
        .content {
            padding: 5% 15%;

            h1 {
                margin-bottom: 2%
            }
            .header-links {
                @include flex-row;
                justify-content: flex-start;
                width: 115%;
                overflow: hidden;

                a {
                    margin-right: 20px;
                }
            }
        }
    }
}