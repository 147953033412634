//---------------------------- Navbar
#navbar {
    #nav-items-container{
        .active {
            font-size: 1.3rem;
        }
        .dropdown {
            .dropdown-content {
                font: {
                    size: 1.1rem;
                }
            }
        }
    }

    #phone-number {
        font-size: 1.65rem;
    }
}

#mobile-navbar {
    #phone-number {
        font-size: 1.8rem;
        text-align: center;

        h3 {
            font-size: 2.5rem;
        }
    }
    #mobile-nav-items-container {
        font-size: 2rem;

        .dropdown {
            #open-dropdown {
                font-size: 1.1rem;
            }
        }
    }
}
//---------------------------- Quote
#quote-container {
    .background-filter {
        a {
            font-size: 1.3vw;
        }
    }
}

//---------------------------- Services
#services {
    .content {
        .big-icon-container {
            span {
                font-size: 1.1rem;
            }
        }   
    }
}

//---------------------------- Valor Proposal
#valor-proposal {
    #valor-proposal-container { 
        .content {
            ul {
                li {
                    font-size: 1.4vw;
                }
            }
        }
    }
}

//---------------------------- Footer
    #footer {
        #footer-container {
            .background-filter {
                #company-contact {
                    text-align: center;

                    .phone-container {
                        i{
                            font-size: 1.3rem;
                        }
                        a {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
        #copyright {
            font-size: 0.9rem;
        }
    }

//-------------------------- Header
#header {
    .background-filter {
        .content {
            .header-links {
                font-weight: 600;
                font-size: 1.2rem;
            }
        }
    }
}

//-------------------------- Services Links
#services-links {
    h3 {
        font-size: 3.5rem;
    }
    .content {
        .img-container {
            .topic-content {
                h4 {
                    font-size: 1.4rem;
                }
                a {
                    button {
                        font-weight: 600;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

//-------------------------- Réalisations
#realisations {
    .topic-content {
        h3 {
            font-size: 2.7vw;
        }
        .column {
            .row {
                h4 {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 2rem;
                }
            }
        }
    }
}

//-------------------------- Services Styles
.services-style {
    .column {
        .row {
            .text-content {
                h2 {
                    font-size: 2.3vw;
                    line-height: 2.7vw;
                }
                p {
                    font-weight: 600;
                    line-height: 1.6rem;
                }
                ul {
                    li {
                        font-size: 0.9rem;
                        font-weight: 600;
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }
}