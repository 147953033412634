/*------------------------------------------------------------------------------------------------ Desktop dropdown animation ------------------------------------------------------------------*/
#navbar {
    #nav-items-container {
        .dropdown {
            &:hover .dropdown-content{
                display: block; 
                animation-name: dropdown-animation;
                animation-duration: 500ms;
                animation-fill-mode: forwards;
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ Mobile dropdown animation ------------------------------------------------------------------*/
#blurred-background {
    #mobile-navbar {
        #mobile-nav-items-container {
            .dropdown {
                #open-dropdown {
                    animation-name: mobile-dropdown-animation;
                    animation-duration: 500ms;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ Quote Falling Animation ------------------------------------------------------------------*/
#quote-container {
    .background-filter {
        .content {
            h2 {
                animation-name: heading-animation;
                animation-duration: 800ms;
                animation-fill-mode: forwards;
            }
            

        }
    }
}

/*------------------------------------------------------------------------------------------------ Footer Creator Part Animation ------------------------------------------------------------------*/
#footer {
    #copyright {
        #creator-part  {
            &:hover {
                    animation-name: creator-animation;
                    animation-duration: 700ms;
                    animation-direction: alternate;
                    animation-iteration-count: infinite;
                }
        }
    }
}


/*------------------------------------------------------------------------------------------------ Keyframes -----------------------------------------------------------------------------------*/

//Desktop dropdown animation keyframes
@keyframes dropdown-animation {

    from {
        opacity: 0;
        top: 100px;
        transform: scale(0.7);
    }
    to {
        opacity: 1;
        top: 80px;
        transform: scale(1);
    }
}

//Mobile dropdown animation keyframes
@keyframes mobile-dropdown-animation {
    
    from {
        height: 2rem;
        opacity: 0;
    }
    to {
        height: 8rem;
        opacity: 1;
    }
}

// Quote falling animation keyframes
@keyframes heading-animation {

    from {
        transform: translate(0, -20rem);
        opacity: 0;
    }

    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

// Creator part animation keyframes
@keyframes creator-animation {

    from {
        transform: scale(1);
    }
    to {
        color: #e4ab38;
        transform: scale(1.1);
    }
}
