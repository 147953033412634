@use '../abstracts/mixins' as *;

#realisations  {
    margin-bottom: 5px;

    .topic-content {
        @include flex-column;
        padding: 5% 15%;

        h3 {
            margin-bottom: 8%;
            text-align: center; 
        }
        .column {
            @include flex-row;
            text-align: center;

            .row {
                @include flex-column;
                width: 45%;
                height: 27rem;
                align-items: center;

                .icon-container {
                    width: 9rem;
                    height: 9rem;
                    margin-bottom: 4%;
                    border-radius: 50%;

                    img {
                        width: 45%;
                        margin-top: 2.4rem;
                    }
                }    
                h4 {
                    width: 80%;
                    margin-bottom: 5%;
                    padding-bottom: 2%;
                }
                ul {
                    width: 100%;
                    padding-right: 5%;

                    li {
                        margin-left: 34%;
                        margin-bottom: 2%;
                        list-style: disc;
                        text-align: left;
                    }
                    .less-margin {
                        margin-left: 18%;
                    }
                } 
            }
        }
    }
}