@use '../abstracts/variables' as *;

/*------------------------------------------------------------------------------------------------ Medium devices ------------------------------------------------------------------*/
@media screen and (max-width: 80rem) {
    #services-links {
        h3 {
            font-size: 2.6rem;
        }
        .content {
            width: 58rem;

            .img-container {
                width: 17rem;
                height: 24rem;

                a {
                    .image {
                        background-size: cover;
                    }
                }
            }
        }
    }

    //-------------------------- Réalisations
    #realisations {
        .topic-content {
            padding: 5% 10%;

            .column {
                .row {
                    width: 48%;

                    ul {
                        li {
                            margin-left: 20%;                            
                        }
                    } 
                }
            }
        }
    }

    //---------------------------- Services Styles
    .services-style {
        .column {
            .row {
                height: 35rem;

                .text-content {
                    padding: 12%;
                }
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ For Tablets ------------------------------------------------------------------*/
@media screen and (max-width: 65rem){
    ::-webkit-scrollbar {
        display: none;
    }

    //---------------------------- Navbar
    #navbar {
        img {
            width: 35%;
        }
        #nav-items-container {
            width: 35%;
            font-size: 0.9rem;

            .active {
                font-size: 1.2rem;
            }
        }
        #phone-number {
            font-size: 1.3rem;

            i {
                margin-right: 5px;
            }
        }
    }

    //------------------------------- Quote
    #quote-container {
        .background-filter {
            .content {
                padding: 12% 5%;

                h2 {
                    font-size: 5vw;
                }
                a {
                    width: 35%;
                    padding: 4%;
                    margin: 15% auto;
                    font-size: 2vw;
                }
            }
        }
    }

    //-------------------------------- Services
    #services {
        padding: 6% 15%;

        h3 {
            width: 90%;
            margin-bottom: 5%;
            font-size: 5vw;   
        }
        .content {
            .big-icon-container {
                margin: 1rem 1rem;

                .icon-container {
                    width: 11rem;
                    height: 11rem;
                    padding: 5%;

                    img {
                        margin-top: 35%;
                    }
                }
            }
        }
    }

    //-------------------------- Valor-proposal
    #valor-proposal {
        #valor-proposal-container {
            h3 {
                font-size: 5vw;
            }
            .content {
                ul {
                    li {
                        font-size: 2.5vw;

                    }
                }
            }
        }
        #image-container {
            img {
                object-position: 27%;
            }
        }
    }

    //-------------------------- Footer
    #footer {
        #footer-container {
            padding: 3% 5% 3% 3%;

            .background-filter {
                #company-valor {
                    p {
                        font-size: 0.9rem;
                    }
                }
                #company-links {
                    width: 39%;

                    h4 {
                        margin-bottom: 7%;
                    }
                    .links {
                        font-size: 1rem;

                        .column1 {
                            margin-right: 1.5rem;
                        }
                    }
                }
            }
        }    
        #copyright {
            padding: 1% 4% 1% 1%;
            #copyright-part {
                font-size: 0.8rem;
            }
            #creator-part {
                a {
                    font-size: 1rem;
                }
            }
        }
        
    }

    //-------------------------- Header
    #header {
        .background-filter {
            .content {
                padding: 9% 13%;

                h1 {
                    font-size: 3rem;
                }
                .header-links {
                    font-size: 1.3rem;
                }
            }
        }
    }

    //-------------------------- Services Links
    #services-links {
        padding: 7% 3%;

        h3 {
            font-size: 4vw;
        }
        .content {
            width: 44rem;
            padding: 3% 0;

            .img-container {
                width: 14rem;
                height: 22rem;

                a {
                    .image {
                        height: 11rem;
                        background-size: cover;
                    }
                }
                .topic-content {
                    h4 {
                        width: 100%;
                        font-size: 1.2rem;
                    }
                    .less-margin {
                        margin-bottom: 1.5%;
                    }
                }
            }
        }
    }

    //-------------------------- Réalisations
    #realisations {
        .topic-content {
            padding: 5% 4%;

            h3 {
                font-size: 1.8rem;
                line-height: 2.2rem;
            }
            .column {
                .row {
                    width: 49%;  
                    h4 {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    //---------------------------- Services Styles
    .services-style {
        padding: 0 1.5%;

        .column {
            margin-bottom: 1%;

            .row {
                height: 23rem;

                .text-content {
                    padding: 8% 3%;

                    h2 {
                        margin-bottom: 5%;
                        font-size: 1.7rem;
                        line-height: 1.8rem;
                    }
                }
                
                .img {
                    border-radius: 10px;
                }
                #maconnerie-guy {
                    background-position: 80%;
                }
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ For Mobile Devices ------------------------------------------------------------------*/
@media screen and (max-width: 45rem) {
    //-----------------Navbar
    #navbar {
        img {
            width: 300px;
        }
        #nav-items-container, #phone-number{
            display: none;
        }
        #humburger-menu {
            display: block;
        }
    }
    #mobile-navbar {
        #phone-number {
            h3 {
                font-size: 2.3rem
            }
            i {
                margin-right: 10px;
            }
        }
        #mobile-nav-items-container {
            width: 50%;
        }
    }

    //-------------------- Quote
    #quote-container {
        .background-filter {
            .content {
                padding: 15% 5%;

                h2 {
                    font-size: 7vw;
                }
                a {
                    width: 55%;
                    margin: 15% auto;
                    padding: 5.5%;
                    font-size: 3.6vw;
                }
            }
        }
    }

    //-------------------- Services
    #services {
        h3 {
            width: 100%;
            margin-bottom: 20%;
            font-size: 7vw;
        }
        .content {
            .row1, .row2 {
                display: block;
            }
            .big-icon-container {
                margin-bottom: 3rem;

                .icon-container {
                    width: 12rem;
                    height: 12rem;
                }
            }
        }
    }

    //---------------------- valor-proposal
    #valor-proposal {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;

        #valor-proposal-container {
            margin-bottom: 5%;

            h3 {
                font-size: 7.5vw;
            }
            .content {
                ul {
                    li {
                        font-size: 4.5vw;
                    }
                }
            }
        }
        #image-container {
            width: 100%;
            padding: 0 1%;
        }
    }

    //-------------------------- Footer
    #footer {
        #footer-container {
            padding: 12% 5%;

            .background-filter {
                flex-direction: column;
                align-items: flex-start;

                #company-valor {
                    display: none;
                }
                #company-links {
                    width: 90%;
                    margin-left: 5%;
                    margin-bottom: 6%;
                    padding-bottom: 3%;
                    border-bottom: 2px solid #EFECEB;
                    .links {       
                        .column1 {
                            ul {
                                li {
                                    margin-bottom: 18%;
                                }
                            }
                        }
                        ul {
                            li {
                                margin-bottom: 7%;
                            }
                        }
                    }
                }
                #company-contact {
                    .phone-container {
                        font-size: 0.8rem;
                        transform: translate(4%);
                    }
                }
            }
        }    
        #copyright {
            flex-direction: column;
            padding: 3% 1%;
            text-align: center;
            #copyright-part {
                margin-bottom: 3%;
            }
        }
    }

    //-------------------------- Header
    #header {
        .background-filter {
            .content {
                padding: 12% 15%;

                h1 {
                    font-size: 1.7rem;
                    margin-bottom: 7%;
                }
                .header-links {
                    font-size: 0.9rem;

                    &::after {
                        height: 0.3rem;
                    }
                }
            }
        }
    }

    //-------------------------- Services Links
    #services-links {
        overflow: hidden;
        padding:  7% 13%;
        background: white;

        h3 {
            width: 120%;
            margin-bottom: 15%;
            font-size: 6.5vw;
        }
        .content {
            flex-direction: column;
            padding: 0 0;

            .img-container {
                width: 17rem;
                height: 23rem;
                margin-bottom: 12%;

                a {
                    .image {
                        height: 50%;
                    }
                }
                .topic-content {
                    h4 {
                        font-size: 1.5rem;
                        margin-bottom: 15%;
                    }
                    .less-margin {
                        margin-bottom: 10%;
                    }
                }
            }
        }
    }

    //--------------------------- Réalisation
    #realisations {
        .topic-content {
            h3 {
                font-size: 1.3rem;
                line-height: 1.7rem;
            }
            .column {
                flex-direction: column;

                .row {
                    width: 80%;
  
                    h4 {
                        margin-bottom: 8%;
                    }
                    ul {
                        li {
                            font-size: 0.8rem;
                        }
                    } 
                }
            }
        }
    }

    //---------------------------- Services Styles
    .services-style {
            .column {
                flex-direction: column;
                width: 90%;
                margin: auto;

                &:not(:last-child) {
                    border-bottom: 3px solid $light-brown;
                }

                .row {
                    width: 100%;
                    height: 100%;
                    justify-content: center;

                    .text-content {
                        padding: 0;

                        h2 {
                            margin: 7% 0;
                            font-size: 1.8rem;
                            line-height: 2rem;
                            text-align: center;
                        }
                        p {
                            margin-bottom: 5%;
                        }
                        ul {
                            margin-left: 8%;
                            margin-bottom: 4%;
                        }
                    }
                    
                    .img {
                        border-radius: 15px;
                    }
                }
                .resize-mobile {
                    margin: 2.5% 0 5% 0;
                    height: 15rem;
                }
                
            }
            .left-image {
                flex-direction: column-reverse;
            }
        }
}
