@use '../abstracts/mixins' as *;

.services-style {
    @include flex-column;
    margin: 5px 0;

    .column {
        @include flex-row;

        .row {
            @include flex-row;
            width: 50%;
            height: 42rem;

            .text-content {
                width: 100%;
                height: 100%;
                padding: 18% 15%;
                overflow-y: auto;

                h2 {
                    margin-bottom: 3%
                }
                p {
                    margin-bottom: 2%;
                }
                ul {
                    margin-left: 5%;
                    margin-bottom: 2%;

                    li {
                        list-style: disc;
                        margin-bottom: 1.7%
                    }
                }
            }
            .big-text-content {
                padding: 5% 15%;
            }
            
            .img {
                @include background-image;
                background-size: cover;
                width: 100%;
                height: 100%;
            }
            
/*------------------------------------------------------------------------------------------------ Images ------------------------------------------------------------------*/
            #construction-plan {
                background-image: url("../../../public/images/construction-plan.webp");
            }
            #construction-guy {
                background-image: url("../../../public/images/construction-guy.webp");
            }
            #drain-francais {
                background-image: url("../../../public/images/drain-francais.webp");
                height: 100%;
            }
            
            #bricks {
                background-image: url("../../../public/images/bricks.webp");
            }
            #crack-reparation {
                background-image: url("../../../public/images/crack-reparation.webp");
            }

            #chimney-sky {
                background-image: url("../../../public/images/chimney-sky.webp");
            }
            #stone-chimney {
                background-image: url("../../../public/images/stone-chimney.webp");
            }
            #masonry-work {
                background-image: url("../../../public/images/masonry-work.webp");
            }
        }
    }
}