@use '../abstracts/mixins' as *;
@use '../abstracts/variables';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    font-family: 'Montserrat', sans-serif;
}

body {
    width: 100vw;
    overflow-x: hidden;

    .main {
        margin-top: 80px;
    }
    header {
        margin-top: 80px;
    }
    h1 {
        @include headers-styling;
        font-size: 3.7rem;
    }
    h2 {
        @include headers-styling;
        font-size: 3.5vw;
        line-height: 120%;
    }
    h3 {
        @include headers-styling;
        font-size: 3.5vw;
        line-height: 120%;
    }
    h4 {
        @include headers-styling;
        text-shadow: 1px 2px 1.5px variables.$dark-brown;
        font-size: 1.6rem;
    }
    ul {
        li {
            list-style: none;
        }
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    button {
        color: white;
        border: none;
        background-color: inherit;
    }
    strong {
        font-weight: inherit;
    }

    #mobile-nav-items-container {
        .dropdown {
            .dropdown-content {
                ul li {
                    list-style:disc;
                }
            }
        }
    }

}

