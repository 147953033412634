@use '../abstracts/mixins' as *;

#valor-proposal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 2rem 1.9rem;

    #valor-proposal-container {
        margin-left: 3%;
        
        h3 {
            margin-bottom: 7%
        }    
        .content {
                ul {
                    li {
                        @include flex-row;
                        justify-content: flex-start;
                        margin-bottom: 3.5%;

                        .checkbox {
                            @include flex-row;
                            justify-content: center;
                            width: 2.2rem;
                            height: 2.2rem;
                            margin-right: 2%;
                        }
                    }
                }
            }
    }
    #image-container {
        width: 50%;
        
        img {
            width: 100%;
            margin: 0;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }
}