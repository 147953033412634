@use '../abstracts/mixins' as *;

#services-links {
    @include flex-column;
    overflow: hidden;
    align-items: center;
    text-align: center;
    padding: 4% 0 6% 0;

    h3 {
        width: 80%;
        margin-bottom: 5%;
    }
    .content {
        @include flex-row;
        width: 70rem;

        .img-container {
            width: 20rem;
            height: 26rem;
            border-radius: 5px;

/*------------------------------------------------------------------------------------- Images -------------------------------------------------------------------------------*/
            a {
                #pipes {
                    background-image: url("../../../public/images/pipes.webp");
                }
                #maconnerie {
                    background-image: url("../../../public/images/maconnerie.webp");
                }
                #cheminees {
                    background-image: url("../../../public/images/Chimney.webp");
                }
                
                .image {
                    @include background-image;
                    border-radius: 10px 10px 0 0;
                    width: 100%;
                    height: 13rem;
                }
            }
/*----------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

            .topic-content {
                padding: 7%;

                h4 {
                    margin-bottom: 15%;
                }
                .less-margin {
                    margin-bottom: 4%;
                }
                a {
                    button {
                        padding: 4% 12%;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }
}