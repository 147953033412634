/*------------------------------------------------------------------------------------------------ Common Header Styling ------------------------------------------------------------------*/
@mixin headers-styling {
    font-family: 'Archivo Black', sans-serif;
    text-shadow: 1px 2px 3px #302624;
}

/*------------------------------------------------------------------------------------------------ Display flex row ------------------------------------------------------------------*/
@mixin flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*------------------------------------------------------------------------------------------------ Display flex column ------------------------------------------------------------------*/
@mixin flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
} 

/*------------------------------------------------------------------------------------------------ Common transitions ------------------------------------------------------------------*/
@mixin transition {
    transition: all 300ms ease-in-out;
}

/*------------------------------------------------------------------------------------------------ Common Background-image ------------------------------------------------------------------*/
@mixin background-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}