@use '../abstracts/mixins' as *;

#footer {
    #footer-container {
        padding: 3% 10%;

        .background-filter {
            @include flex-row;    
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;

            /*----------------------------------------------------------------------------- Company valors paragraph ------------------------------------------------------------------*/
            #company-valor {
                width: 35%;

                img {
                    width: 90%;
                    margin-bottom: 7%;
                    padding: 0.4rem;
                    border-radius: 10px;
                }
                p {
                    width: 80%;
                    margin-left: 4%;
                    text-align: center;
                }
            }
            /*------------------------------------------------------------------------------- List of all company links -----------------------------------------------------------*/
            #company-links {
                h4 {
                    margin-bottom: 10%;
                }
                .links {
                    @include flex-row;
                    align-items: flex-start;

                    .column1 {
                        margin-right: 2rem;
                    }
                    ul {
                        li {
                            margin-bottom: 0.4rem;
                        }
                    }
                }
            }
            /*------------------------------------------------------------------------------ Company contact(s) ------------------------------------------------------------------*/
            #company-contact {
                h4 {
                    margin-bottom: 10%;
                }
                .phone-container {
                    i{
                        margin-right: 0.8rem;
                    }
                }
            }
        }
    }
    
    /*-------------------------------------------------------------------------------------- Copyright ----------------------------------------------------------------------*/
    #copyright {
        @include flex-row;
        padding: 1% 1.8%;

        #creator-part {
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }    
}