@use '../abstracts/mixins' as *;

#quote-container {
    @include background-image;
    background-image: url("../../../public/images/working-table.webp");
    background-size: cover;
    width: 100%;
    margin-bottom: 5px;

    .background-filter {
        width: 100%;

        .content {
            padding: 10% 15%;

            h2 {
                text-align: center;
            }
            a {
                @include flex-row;
                justify-content: center;
                width: 25%;
                padding: 1.8%;
                margin: 10% auto;
                text-align: center; 
                border-radius: 0;
                appearance: button;
                -webkit-appearance: none;
            }
        }
    }
}