@use '../abstracts/mixins' as *;

/*------------------------------------------------------------------------------------------------ Navbar ------------------------------------------------------------------*/
#navbar {
    @include flex-row;
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    box-shadow: 1px 1px 4px 0 rgb(114, 112, 112);
    z-index: 2;
    
    img {
        width: 24%;
        margin-top: 4px;
    }

    #nav-items-container {
        @include flex-row;
        width: 40%;

        .fa {
            margin-left: 7px;
        }

        .active, li:hover  {
            text-decoration: underline;
        }


        .dropdown {
            @include flex-row;
            height: 80px;

            .dropdown-content {
                display: none;
                position: absolute;
                top: 80px;
                padding: 1.1% 1.5%;
                box-shadow: 1px 0 3px 0.5px rgb(83, 82, 82);

                ul {
                    @include flex-column;

                    li {
                        padding: 10px;
                    }
                }
            }
        }
        
    }
    #phone-number {
        margin-right: 2%;
        
        i {
            margin-right: 10px;
        }
    }

    /*------------------------------------------------------------------------------------------------ Humburger Menu for Mobile ---------------------------------------------------------------*/
    #humburger-menu {
        @include transition;
        display: none;
        width: 30px;
        height: 20px;
        margin-right: 22px;

        .humburger-bars {
            width: 30px;
            height: 4px;
            border-radius: 5px;

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }

        //--------------------------------- Animation for the Humburger Menu when it's pressed ------------------------------//
        #open-top {
            @include transition;
            transform: rotate(-45deg) translate(-8px, 2px);
        }
        #close-top {
            @include transition;
            transform: rotate(0deg) translate(0, 0);
        }

/////////////////////////////////////////////////////////////
        #fade-away-middle {
            @include transition;
            transform: translate(-10px);
            opacity: 0;
        }
        #fade-in-middle {
            @include transition;
            transform: translate(0);
            opacity: 1;
        }

/////////////////////////////////////////////////////////////
        #open-bottom {
            @include transition;
            transform: rotate(45deg) translate(-9px, -4px);
        }
        #close-bottom {
            @include transition;
            transform: rotate(0deg) translate(0, 0);
        }


        &:hover {
            cursor: pointer;
        }
    }
}


/*------------------------------------------------------------------------------------------------ Dropdown Links for Mobile ------------------------------------------------------------------*/
#blurred-background {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 1;

    #mobile-navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        #phone-number {
            margin: 2.5rem 0;

            h3 {
                margin-bottom: 0.5rem;
            }
            i {
                margin-right: 1rem;
            }
        }

        #mobile-nav-items-container {
            width: 15rem;
            margin-left: 5.5rem; 

            li {
                margin-bottom: 2rem;
            }
//----------------------- Hidden Dropdown ---------------------//
            .dropdown {
                .dropdown-content {
                    display: none;
                }

//---------------------- Revealed Dropdown --------------------//
                #open-dropdown {
                    display: block;
                    width: 91%;
                    margin-top: -6%;
                    margin-left: 15%;

                    li {
                        margin-bottom: 10px;
                    }
                }
                li {
                    i {
                        margin-left: 1rem;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ Animation for Mobile Dropdown ---------------------------------------------------------------*/
.open-mobile-navbar {
    transition: all 400ms ease-in-out;
    top: 80px;
}

.close-mobile-navbar {
    transition: all 400ms ease-in-out;
    top: -2000px;
}