@use '../abstracts/mixins' as *;

#services {
    @include flex-column;
    margin-bottom: 5px;
    padding: 4% 15%;
    align-items: center;
    
    h3 {
        width: 60%;
        margin-bottom: 3%;
        text-align: center;
    }
    .content {
        @include flex-column;
        align-items: center;
        
        .row1, .row2 {
            @include flex-row;
            text-align: center;
        }
        .big-icon-container {
            margin: 2rem 2rem;
            padding-bottom: 1rem;
            border-radius: 8px;
    
            .icon-container {
                width: 13rem;
                height: 11rem;
                margin-bottom: 1rem;
                padding: 10%;
                border-radius: 5px 5px 0 0;

                img {
                    width: 45%;
                    margin-top: 25%;
                }
            }
            span {
                display: block;
            }
        } 
    }
}