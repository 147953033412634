@use './variables' as *;
@use './mixins' as *;

::selection {
    color: lighten($yellow, 15%);
    background-color: $orange;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    @include transition;
    background: linear-gradient(to bottom, $orange, $yellow);
    border-radius: 20px;

    &:hover {
        background-color: $yellow;
    }
}
::-webkit-scrollbar-track {
    background-color: $dark-brown;
}

/*------------------------------------------------------------------------------------------------ Navbar Colors ------------------------------------------------------------------*/
#navbar {
    background-color: white;

    #nav-items-container {
        color: $light-gray;
        
        .active {
            color: $dark-gray;
        }
        li{
            transition: color 300ms ease-in-out;

            &:hover{
                color: $dark-gray;
            }
        }
        .dropdown {
            .dropdown-content {
                color: white;
                background-color: $orange;

                li {
                    color: white;

                    &:hover {
                        background-color: rgba(236, 229, 229, 0.473);
                    }
                }
            }
        }
    }

    #phone-number {
        @include transition;
        color: $orange;

        &:hover {
            color: $yellow;
        }
    }
    #humburger-menu {
        .humburger-bars {
            background-color: $orange;
        }
    }
    
}

/*------------------------------------------------------------------------------------------------ Mobile Links Dropdown Colors ----------------------------------------------------------------*/
#blurred-background {
    backdrop-filter: blur(1rem);

    #mobile-navbar {
        color: white;
        background-color: $brown;
        opacity: 0.9;

        #phone-number {
            @include transition;
            color: $yellow;

            h3 {
                color: $orange;
            }
            &:hover {
                color: $orange;
            } 
        }
        #mobile-nav-items-container {
            .active {
                color: $yellow;
            }
            li:hover {
                color: $yellow;
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ Quote ------------------------------------------------------------------*/
#quote-container {
    color: white;

    .background-filter {
        background-color: #403330bd;

        .content {
            a {
                background-color: $yellow;
                transition: background-color 300ms ease-in-out;

                &:hover {
                    background-color: $orange;
                }
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ Services ------------------------------------------------------------------*/
#services {
    background-color: $brown;
    color: white;

    .content {
        .big-icon-container {
            @include transition;
            background-color: $orange;
            box-shadow: 0px 0px 1px 2px $dark-brown;

            .icon-container {
                @include transition;
                background-color: $light-brown;

                img {
                    filter: invert(100%);
                }
                &:hover {
                    background-color: $dark-brown;
                    transform: scale(1.005);
                }
            }
            &:hover {
                color: $yellow;
                transform: scale(1.09);
            }
        }
            
    }
}
/*------------------------------------------------------------------------------------------------ Valor Proposal ------------------------------------------------------------------*/
#valor-proposal {
    background-color: $beige;

    #valor-proposal-container {
        h3 {
            color: $dark-brown;
            text-shadow: 0.5px 0.5px 1px $light-brown;
        }
        .content {
            ul {
                li {
                    @include transition;
                    &:hover {
                        color: $orange;
                    }
                    .checkbox {
                        background-color: $dark-beige;
                    }
                }
            }
        }
        
    }
}

/*------------------------------------------------------------------------------------------------ Footer ------------------------------------------------------------------*/
#footer {
    #footer-container {
        background: linear-gradient(to bottom, darken($darker-beige, 47%) 1%, $dark-brown);
        color: $darker-beige;

        .background-filter {
            #company-valor {
                img {
                    background-color: $beige;
                }
            }
            #company-links {
                h4 {
                    color: white;
                }
                .links {
                    ul {
                        li {
                            @include transition;

                            &:hover {
                                color: $yellow;
                            }
                        }
                    }
                }
            }
            #company-contact {
                h4 {
                    color: white;
                }
                .phone-container {
                    @include transition;
                    color: $yellow;

                    &:hover{
                        color: $orange;
                    }
                }
            }
        }
    }
    #copyright {
        background-color: darken($dark-brown, 3%);
        color: white;
        border-top: 2px solid lighten($light-brown, 5%);
    }
    
}

/*------------------------------------------------------------------------------------------------ Header ------------------------------------------------------------------*/
#header {
    color: white;

    .background-filter {
        background-color: #403330c5;

        .content {
            .header-links {
                opacity: 0.8;

                .active {
                    color: $yellow;
                }
                a {
                    @include transition;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 1.1rem;
                        margin-top: 3px;
                        margin-left: 10px;
                        background-color: $darker-beige;
                        border: 5px;
                    }
                    &:hover {
                        color: $yellow;
                    }
                }
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ Services Links ------------------------------------------------------------------*/
#services-links {
    background: linear-gradient(to bottom, white 72%, $dark-beige); 
    color: $dark-brown;
    
    h3 {
        text-shadow: 0 0 1px $light-brown;
    }
    .content {
        .img-container {
            background-color: $beige;

            a {
                .image{
                    @include transition;

                    &:hover { 
                        background-size: 115%;
                        filter:brightness(60%);
                    }
                }
            }
            .topic-content {
                h4 {
                    @include transition;
                    text-shadow: 0 0 0 $dark-brown;

                    &:hover {
                        color: $orange;
                    }
                }
                a {
                    button {
                        @include transition;
                        background-color: lighten($yellow, 6%);

                        &:hover {
                            cursor: pointer;
                            background-color: lighten($yellow, 15%);
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ Réalisations ------------------------------------------------------------------*/
#realisations {
    .topic-content {
        background: linear-gradient(to bottom, lighten($light-brown, 15%), $dark-brown);

        h3 {
            color: white;
            text-shadow: 1px 2px 2px $light-brown;
        }
        .column {
            .row {
                @include transition;
                background-color: white;
                margin-bottom: 2rem;
                border-radius: 15px;
                 
                .icon-container {
                    @include transition;
                    background-color: $brown;
                    img {
                        filter: invert(100%);
                    }
                }    
                h4 {
                    text-shadow: 0 0 1px $light-brown;
                    border-bottom: 2px solid $darker-beige;
                }
                ul {
                    color: $light-brown;

                    li {
                        @include transition;

                        &:hover {
                            color: $yellow;
                        }
                    }
                }
                &:hover {
                    transform: scale(1.03);
                    box-shadow: 0 0 0 4px $dark-brown;
                    .icon-container {
                        background-color: $orange;
                    }
                }
            }
        }
    }
}

/*------------------------------------------------------------------------------------------------ Services Style ------------------------------------------------------------------*/
.services-style {
    .column {
        .row {
            .text-content {
                h2 {
                    transition: color 300ms ease-in-out;
                    color: $brown;
                    text-shadow: 0 0 0 $light-brown;

                    &:hover {
                        color: $yellow;
                    }
                }
                p {
                    color: darken($light-gray, 3%);
                }
                ul {
                    li {
                        color: darken($light-gray, 3%);
                    }
                }
            }
        }
    }
}